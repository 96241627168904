.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header-underline {
    border: 1px solid var(--lines);
}

main.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content-container {
    flex-grow: 1;
    margin: 15px 0;
    padding: 15px;
    border: 1px solid var(--lines);
    border-radius: 10px;
    box-shadow: 0 0 5px var(--lines);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
}


