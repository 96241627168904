.navigation {
    display: flex;
    flex-direction: row;
    margin: 25px 0 0 0;
}

.navigation-link {
    font-size: 14px;
    padding: 0 10px 10px;
    text-decoration: none;
}

.navigation-link:not(.active) {
    color: var(--secondary-color)
}

.navigation-link.active {
    border-bottom: 3px solid var(--primary-color);
}
