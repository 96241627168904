@import url('open-iconic/font/css/open-iconic-bootstrap.min.css');

:root {
  --primary-color: #4287f5;
  --secondary-color: #9a9696;
  --lines: #d4d4d4;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1:focus {
  outline: none;
}

a, .btn-link {
  color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unset-button {
  all: unset;
  cursor: pointer;
}

.blink {
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  25% { opacity: 0.5; }
  50% { opacity: 0.0; }
  75% { opacity: 0.5; }
  100% { opacity: 1; }
}
