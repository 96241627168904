.LinkWithIcon {
    display: flex;
    flex-direction: row;
    color: #0000EE;
    cursor: pointer;
}

.LinkWithIconText {
    margin-left: 5px;
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}