footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 15px;
    font-size: 12px;
    color: var(--secondary-color)
}

.tapoint-logo {
    height: 25px;
}
