header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    height: 60px;
}

.facility-name {
    font-weight: bold;
    font-size: 18px;
}

.log-out-button-icon {
    font-size: 26px;
    color: var(--primary-color);
}