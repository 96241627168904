.Order {
    margin: 15px 0 5px;
}

.order-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order-buttons button {
    margin: 0 5px 0 0;
}